<template>
  <main class="main">
    <parked-hero :background-texture="cover" />
  </main>
</template>

<script>
import HeroParked from '../parked/HeroParked.vue'

export default {
  meta: {
    title: 'Parked',
  },
  components: {
    'parked-hero': HeroParked,
  },
  layout: 'parked',
  data() {
    return {
      title: 'Parked',
      cover: 'covers/parked-hero',
    }
  },
  head: function () {
    return {
      title: this.title,
    }
  },
}
</script>
