<template>
  <section class="parked">
    <LazyBackgroundWrapper
      :background-path="backgroundTexture"
      background-class="parked__background-lazy"
      :not-lazy="true"
    />
    <div class="parked__background">
      <div class="container">
        <div class="columns -align-horizontal">
          <div class="column is-eight is-ten-medium is-twelve-xsmall">
            <div class="parked__wrapper">
              <div class="parked__title">
                <h1>Hi There!</h1>
              </div>
              <div class="parked__description">
                <h1>
                  This Domain is parked by
                  <span>Graffino.com</span>
                </h1>
              </div>

              <div class="parked__separator">
                <hr />
              </div>
              <div class="parked__description">
                <h2>Any questions?</h2>
              </div>
              <div class="parked__description">
                <p>
                  Just send us an email at:
                  <span>support@graffino.com</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ParkedHero',
  props: {
    backgroundTexture: {
      type: String,
      default: 'paper-texture',
    },
  },
}
</script>
